<script setup lang="ts">
const { t } = useI18n()
</script>

<template>
  <div class="mx-auto max-w-screen-xl">
    <div class="container mx-auto text-center max-w-4xl px-4 py-6 sm:px-8 sm:py-10">
      <div class="text-gray-600 text-7xl font-bold mb-5">
        404
      </div>

      <div class="leading-relaxed text-gray-800 text-3xl mb-5">
        {{ t('page_not_found') }}
      </div>

      <ButtonToHome />
    </div>
  </div>
</template>
